.exam-container {
  max-width: 1440px;
  margin: auto;
  padding-bottom: 20px;
  &__form {
    display: flex;
    margin: 30px auto;
    justify-content: center;
    width: 85%;
    &__title {
      width: 33%;
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: 500;
    }

    &__item {
      width: 67%;
      .form-select {
        width: 50%;
      }
      &__note {
        margin-top: 8px;
        font-weight: 500;
        font-size: 16px;
        color: red;
      }
    }

    .progress {
      width: 50%;
      height: 1.2rem;
      .bg-success {
        background-color: #338f84 !important;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .file-score {
      color: rgb(55, 79, 211);
      cursor: pointer;
      font-weight: 500;
      margin-left: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
