.ui-content {
  height: 100%;
  padding: 30px 0 30px;
  width: 90%;
  margin: auto;

  &__children {
    padding-top: 85px;
  }
}
