.app-button {
  width: 200px;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
  border-radius: 10px;
  padding: 6px;
  &.button-primary {
    background-color: #338f84;
    border-color: #338f84;
    &:hover,
    &:active {
      background-color: #285751 !important;
      border-color: #285751 !important;
    }
    &:disabled {
      background-color: #62a59d !important;
      border-color: #62a59d !important;
      opacity: 0.65;
    }
  }
  &.button-secondary {
    background-color: #d34747;
    &:hover,
    &:active {
      background-color: #852e2e !important;
      border-color: #852e2e !important;
    }
    &:disabled {
      background-color: #a16767;
      border-color: #a16767;
      opacity: 0.65;
    }
  }
}
