.ui-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 40px;
  background-color: #809d9d;
  &__logo {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    &__img {
      width: 110px;
      height: 45px;
      margin-right: 50px;
      display: flex;
      align-items: center;
      img {
        width: auto;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  &__link {
    gap: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__item {
      text-decoration: none;
      color: #fff;
      font-weight: 500;
      width: max-content;
      height: max-content;
      text-align: center;
      img {
        width: auto;
        height: 36px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 6px;
      }
    }
  }
}
