.ui-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &__loader {
    .text-success {
      color: #338f84 !important;
      width: 50px !important;
      height: 50px !important;
    }
  }
}
