.drop-zone__container {
  position: relative;
  .drop-zone {
    text-align: center;
    padding: 14px;
    border: 3px dashed #bfc5cb;
    background-color: #e1e9f0;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    .text-guide {
      font-size: 16px;
      flex-basis: 60%;
    }
  }

  .btn-upload__folder {
    width: 120px;
    font-weight: 400;
    position: absolute;
    top: 20px;
    right: 10%;
  }

  .file-container {
    width: 100%;
    margin: 10px auto 14px;
    max-height: 210px;
    overflow: auto;
    border: 2px dashed #bfc5cb;
    padding: 10px;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(56, 56, 56, 0.3);
      border-radius: 10px;
      background-color: #dbdbdb;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #dbdbdb;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(56, 56, 56, 0.3);
      background-color: #adacac;
    }
    &__selected {
      color: #000;
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      &::before {
        content: "•";
        margin-right: 8px;
        display: inline-block;
        font-size: 16px;
      }
    }
    &__icon-remove {
      cursor: pointer;
      margin-left: 8px;
      padding: 4px 10px;
      color: red;
      svg {
        font-size: 20px;
      }
    }
  }

  .file-remove-all {
    margin-top: 14px;
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: red;
      svg {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
}
