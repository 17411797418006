.ui-modal-error {
  .modal-header {
    padding: 6px 16px;
  }
  .modal-body {
    min-height: 90px;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    justify-content: center;
  }
  .modal-footer {
    border-top: unset;
    justify-content: center;
    padding-top: 0;
    button {
      padding: 6px 32px;
    }
  }
}
