.toast-message-custom {
  z-index: 9999 !important;
  .toast-message-danger {
    .toast-header,
    .toast-body {
      background-color: #f9e1e5;
      color: #af233a;
      border-color: #f4c8cf;
    }
  }

  .toast-message-success {
    .toast-header,
    .toast-body {
      background-color: #d6f0e0;
      color: #0d6831;
      border-color: #c0e7d0;
    }
  }
}
